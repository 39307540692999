import { StoryTransitionType } from 'types';

export interface IOption {
  value: StoryTransitionType;
  label: string;
}

export const TRANSITION_TYPE_OPTIONS: Array<IOption> = [
  {
    value: 'none',
    label: 'Без перехода',
  },
  {
    value: 'marathon_single',
    label: 'Вызов (одиночный)',
  },
  {
    value: 'marathon_team',
    label: 'Вызов (командный)',
  },
  {
    value: 'news',
    label: 'Новость',
  },
  {
    value: 'showcase',
    label: 'Витрина',
  },
  {
    value: 'standards_calculator',
    label: 'Калькулятор нормативов',
  },
  {
    value: 'testing_centers',
    label: 'Центры тестирования',
  },
  {
    value: 'standards_completion',
    label: 'Как выполнять нормативы?',
  },
  {
    value: 'standards_preparation',
    label: 'Как подготовиться к выполнению нормативов?',
  },
  {
    value: 'gto_auth',
    label: 'Авторизация в ГТО',
  },
  {
    value: 'questions',
    label: 'Вопрос-ответ',
  },
  {
    value: 'link',
    label: 'Внешная ссылка',
  },
];

export const TRANSITION_WITH_OBJECTS: Array<StoryTransitionType> = [
  'marathon_single',
  'marathon_team',
  'showcase',
  'news',
];

export const TRANSITION_OBJECTS_TYPES = {
  MARATHON_SINGLE: 'marathon_single',
  MARATHON_TEAM: 'marathon_team',
  SHOWCASE: 'showcase',
  NEWS: 'news',
};
